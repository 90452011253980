<template>
  <div id="home" class="home">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel height="500px">
        <el-carousel-item v-for="item in carousel" :key="item.carouselId">
          <img style="height:500px;" :src="item.imgPath"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 招聘公告 -->
    <div class="post-published">
      <div class="published-header">
        <span>招聘信息</span>
        <el-button v-if="btnToResultEnabled" type="primary" @click="toStatus" icon="el-icon-s-order" size="small">
          报名查询
        </el-button>
      </div>

      <div v-for="post in postPublishedList" class="published-content" :key="post.id">
        <div class="post-title" @click="showDetail(post.id)">
          <span>{{ post.post }}</span>
          <span>发布日期：{{ post.createTime.substr(0, 10) }}</span>
        </div>
      </div>
      <el-pagination
          @current-change="handleCurrentChange"
          :page-size.sync="queryParams.pageSize"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {listPostPublished} from '@/api/Home'

export default {
  data() {
    return {
      // 轮播图数据
      carousel: [
        {carouselId: '1', imgPath: require('@/assets/imgs/carousel/1.jpg')},
        {carouselId: '2', imgPath: require('@/assets/imgs/carousel/2.jpg')},
        {carouselId: '3', imgPath: require('@/assets/imgs/carousel/3.jpg')},
        {carouselId: '4', imgPath: require('@/assets/imgs/carousel/4.jpg')},
        {carouselId: '5', imgPath: require('@/assets/imgs/carousel/5.jpg')},
      ],
      // 总条数
      total: 0,
      // 岗位招聘需求表格数据
      postPublishedList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 5
      },
      btnToResumeEnabled: false,
      btnToResultEnabled: false,
      resume: null
    };
  },
  filters: {
    replaceHtmlTag(content) {
      let dd = content.replace(/<[^>]+>/g, "");//截取html标签
      let dds = dd.replace(/ /ig, "");//截取空格等特殊标签
      return dds
    }
  },
  created() {
    // 判断当前登录用户是否已经填报简历
    let resume = sessionStorage.getItem("resume")
    if (resume) {
      this.resume = resume
      this.btnToResumeEnabled = false
      this.btnToResultEnabled = true
    } else {
      this.btnToResumeEnabled = true
      this.btnToResultEnabled = false
    }
    this.getList()
  },
  methods: {
    handleCurrentChange(currentPage) {
      this.queryParams.pageNum = currentPage
      this.getList()
    },
    getList() {
      listPostPublished(this.queryParams).then(response => {
        this.postPublishedList = response.data.rows;
        this.total = response.data.total;
      })
    },
    showDetail(id) {
      this.$router.push({path: "/post_detail/" + id});
    },
    toResume(id) {
      this.$router.push({path: "/user_resume/" + id});
    },
    toStatus() {
      this.$router.push({path: "/status"});
    }
  }
}
</script>

<style scoped>
/* 轮播图CSS */
.block, .post-published {
  margin: 0 auto;
  max-width: 1440px;
}

.post-published {
  max-width: 1380px;
  background-color: #ffffff;
  margin-top: 32px;
  padding: 0 30px 82px 30px;
}

.published-content {
  margin-top: 28px;
  padding-bottom: 14px;
  border-bottom: 1px dashed #E0E0E0;
}

.published-header, .post-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.published-header {
  height: 50px;
  border-bottom: 1px solid #E0E0E0;
}

.published-header > span {
  font-size: 24px;
  font-weight: 600;
  color: #1677FF;
  line-height: 33px;
  border-left: 4px solid #1677FF;
  padding-left: 8px;
}

.post-title:hover {
  cursor: pointer;
}

.post-title > span {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.post-content, .post-time > span, .btn-detail {
  font-size: 16px;
  font-weight: 400;
}

.post-content {
  margin-top: 12px;
  color: #333333;
}

.post-time {
  margin-top: 16px;
  margin-bottom: 20px;
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

</style>
